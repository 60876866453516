import React, { useEffect, useState } from 'react'
import Wrapper from '../../../components/wrapper'
import globalData from '../../../../globalData.json'
import EmployerForm from '../../../components/pageComponents/employerForm'
import {
  CalendarIcon,
  ElectronIcon,
  TimerIcon,
} from '../../../components/icons'

const OPTION_LIST = [
  {
    title: 'Strategic Hiring',
    desc: 'Plan ahead and build a stronger team with consistent access to qualified talent.',
    icon: () => (
      <div className="w-[30px]">
        <CalendarIcon fillColor={'#6d1e36'} />
      </div>
    ),
  },
  {
    title: 'Long-Term Efficiency',
    desc: 'Streamline your recruiting over time with reliable access to new candidates.',
    icon: () => (
      <div className="w-[30px]">
        <TimerIcon fillColor={'#6d1e36'} />
      </div>
    ),
  },
  {
    title: 'Always-On Talent',
    desc: 'Never miss the right hire—your talent pool stays active all year long.',
    icon: () => (
      <div className="w-[20px]">
        <ElectronIcon fillColor={'#6d1e36'} />
      </div>
    ),
  },
]

export default function EmpAnnualJoinNow({ location }) {
  const [subscription, setSubscription] = useState(null)
  const params = new URLSearchParams(location.search)

  useEffect(() => {
    let _prices = globalData?.EmployerCatalog?.prices?.length
      ? globalData?.EmployerCatalog?.prices
      : []
    const annualPrice = _prices.find(item => item.name === 'Annual Unlimited')
    if (annualPrice) {
      setSubscription(annualPrice)
    }
  }, [])

  return (
    <Wrapper
      location={location}
      title="Medreps | B2B Annual Subscription"
      description="Access the latest salary trends and insights in biotech sales, with valuable data and analysis to help you make informed decisions about your medical sales career."
    >
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-8 items-center">
          <div className="p-8 md:p-0">
            <img src="/images/emp-join-now.png" />
            <h4 className="mt-8 text-[#6d1e36] leading-[1.25] text-[52px] font-bold font-georgia">
              Year-Round Access to Pre-vetted Medical Sales Professionals
            </h4>
            <p className="leading-[1.25] font-arial text-xl mt-10">
              Build a reliable, cost-effective pipeline for long-term hiring
            </p>
            <ul className="list-disc pl-8 text-xl leading-[1.8] font-arial">
              <li>
                <strong className="text-[#c6004c]">71%</strong> of MedReps
                members work in medical or pharmaceutical sales.
              </li>
              <li>
                <strong className="text-[#c6004c]">73%</strong> hold at least a
                bachelor’s degree.
              </li>
              <li>
                <strong className="text-[#c6004c]">83%</strong> are looking for
                new opportunities.
              </li>
            </ul>
          </div>
          <div className="p-8">
            <EmployerForm
              subscription={subscription}
              promoCode={params.get('utm_promocode')}
            />
          </div>
        </div>
        <div className="gap-4 block md:grid grid-cols-3 mb-4 ">
          {OPTION_LIST.map(item => (
            <div className="bg-white shadow-profileCard rounded-lg p-8 m-4">
              <div className="w-[30px] max-h-[30px] mb-4">{item.icon()}</div>
              <h1 className="m-0 sm:mb-3 font-arial text-2xl font-normal text-[#333]">
                {item.title}
              </h1>
              <p className="font-arial text-sm md:text-base mb-0 text-[#333]">
                {item.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  )
}
