import React, { useState } from 'react'
import { Form, Input, Checkbox, message, Select } from 'antd'
import useGMT from '../../../hooks/useGTM'
import { auth0Roles, strapiURL } from '../../../config'
import { setProfileData, setProducts } from '../../../redux/actions/user'
import axios from 'axios'
import cookie from 'react-cookies'
import { useDispatch } from 'react-redux'
import { navigate } from 'gatsby'
import { returnCreditType } from '../../../functions'

const { Option } = Select

export default function EmployerForm({ subscription, promoCode }) {
  const [submitLoading, setSubmitLoading] = useState(false)

  const [employerForm] = Form.useForm()
  const dispatch = useDispatch()

  const userCheckhandler = async values => {
    let preCheckData = {
      email: (values.email || '').toLowerCase(),
    }

    const result = await axios.post(
      strapiURL + '/auth0/checkEmail',
      preCheckData
    )

    const resultEmail = result.data

    if (resultEmail.format === false) {
      message.error('Email format is invalid.')
      return
    }

    if (resultEmail.mxLookup === false) {
      message.error('Cannot verify email domain')
      return
    }

    if (resultEmail.smtp === false) {
      message.error('Cannot validate email smtp setting')
      return
    }

    const regex =
      /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])).{8,16}$/

    if (values.confirm.length < 8 || !values.confirm.match(regex)) {
      message.error(
        'Your password must contain at least 8 characters including at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*).'
      )
      return
    }

    // pushRegisterEvent(false)

    setSubmitLoading(true)
    let data = {
      email: (values.email || '').toLowerCase(),
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      last_login: new Date(),
      roleId: auth0Roles.Employer.id,
      marketing_preferences: values.marketing_preferences ? true : false,
      companyName: values.name,
      staffing_firm: values.staffing_firm,
    }

    axios
      .post(strapiURL + '/auth0/signup', data)
      .then(res => {
        const userData = res.data
        const responseRole = userData.role.id
        let profile = userData?.profile
        delete userData.profile

        cookie.save('userRole', responseRole, { path: '/', maxAge: 259200 })
        cookie.save('user', userData, { path: '/', maxAge: 259200 })

        dispatch(setProfileData(profile))

        if (!subscription && typeof window !== 'undefined') {
          window.location.href = '/employer/membership/'
        } else if (!userData.isCheckoutRequired) {
          alert('You already have a subscription with us.')

          // Retrive updated subscription
          axios
            .get(
              strapiURL +
                `/employers/${userData?.user?.id}/subscriptionsAndCredits`
            )
            .then(res => {
              dispatch(setProducts(res?.data))
              navigate('/employer-dashboard/', {
                state: { firstRegistration: true },
              })
            })
            .catch(err => {
              console.log(
                'Something wrong while retrive subscriptionsAndCredits.'
              )
            })
        } else {
          localStorage.setItem(
            'emp-subscription',
            JSON.stringify({
              subscription: subscription.price,
              addons: subscription.addOns,
            })
          )

          let mode = 'payment'
          let url =
            '/thankyou/?success=true&session_id={CHECKOUT_SESSION_ID}&first_registration=true'
          let _checkoutPrices = []
          if (typeof window !== 'undefined') {
            _checkoutPrices.push({
              price: subscription?.price?.priceId,
              quantity: subscription?.price?.recurring
                ? 1
                : subscription?.price?.quantity,
            })
            if (subscription?.price?.recurring) {
              mode = 'subscription'
            } else {
              url += '&employerRedirect=post'
            }
            if (subscription?.addOns?.length) {
              for (const item of subscription?.addOns) {
                if (
                  returnCreditType(item.credit_type).type === 'resumeSearch' ||
                  returnCreditType(item.credit_type).type ===
                    'addonSubscription'
                ) {
                  if (item.is_selected) {
                    _checkoutPrices.push({
                      price: item.priceId,
                      quantity: 1,
                    })
                    mode = 'subscription'
                  }
                } else {
                  if (item.quantity) {
                    _checkoutPrices.push({
                      price: item.priceId,
                      quantity: item.quantity,
                    })
                  }
                }
              }
            }
          }

          axios
            .post(strapiURL + '/stripe/session/checkout', {
              line_items: _checkoutPrices,
              success_url: window.location.origin + url,
              cancel_url: window.location.origin,
              customerEmail: (values.email || '').toLowerCase(),
              customer: userData.customer_id,
              promoCode: promoCode,
              mode: mode,
              clientId: cookie.load('_ga')?.substring(6),
              sessionId: cookie
                .load(
                  process.env.GATSBY_GOOGLE_ANALYTICS_MEASUREMENT_ID.replace(
                    'G-',
                    '_ga_'
                  )
                )
                ?.split('.')[2],
            })
            .then(res => {
              if (typeof window !== 'undefined') {
                window.history.replaceState(
                  '',
                  '',
                  `${window.location.origin}/thankyou/`
                )
                window.location.href = res?.data?.url
              }
            })
            .catch(err => {
              message.error('Something wrong while checkout.')
            })
        }
      })
      .catch(err => {
        message.error(err?.response?.data?.message[0].error)
        setSubmitLoading(false)
      })
  }

  return (
    <div className="w-full">
      <Form
        form={employerForm}
        name="candidate-form"
        layout="vertical"
        onFinish={userCheckhandler}
      >
        <Form.Item
          name="staffing_firm"
          label="Is your organization a recruitment/staffing company?"
          className="theme-form-item-update font-arial"
          rules={[
            {
              required: true,
              message: 'Required.',
            },
          ]}
        >
          <Select className="w-full" size="large">
            <Option value={null}>Select</Option>
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          className="theme-form-item-update font-arial"
          rules={[
            {
              type: 'email',
              message: 'Invalid Email.',
            },
            {
              required: true,
              message: 'Required.',
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          name="name"
          label="Company Name"
          className="theme-form-item-update font-arial"
          rules={[
            {
              required: true,
              message: 'Required.',
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="firstName"
          label="First Name"
          className="theme-form-item-update font-arial"
          rules={[
            {
              required: true,
              message: 'Required.',
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          className="theme-form-item-update font-arial"
          rules={[
            {
              required: true,
              message: 'Required.',
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          className="theme-form-item-update font-arial"
          rules={[
            {
              required: true,
              message: 'Required.',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          dependencies={['password']}
          label="Confirm password"
          className="theme-form-item-update font-arial"
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                )
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          valuePropName="checked"
          name="agree"
          className="theme-form-item-update font-arial"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error('Required')),
            },
          ]}
        >
          <div className="flex items-start">
            <span className="mr-[4px] text-xl text-[#ff4d4f]">*</span>
            <Checkbox className="font-arial">
              I have read and agree to the MedReps Terms & Conditions
              <a
                className="text-black text-[12px] font-bold opacity-90 ml-3 inline-block"
                href="/privacy-policy"
                target="_blank"
              >
                {' '}
                (View)
              </a>
            </Checkbox>
          </div>
        </Form.Item>
        <p className="pt-0 font-arial text-last mb-4">
          By creating a MedReps account, you agree to receive marketing
          communications, updates, and relevant information about our services
          and offers. We are committed to respecting your preferences, and you
          can unsubscribe from these communications at any time by following the
          link provided in our emails or contacting our support team. Your
          privacy is important to us, and we ensure that your information is
          safe and handled in accordance with{' '}
          <a href="/privacy-policy" target="_blank">
            MedReps' privacy policy
          </a>
          .
        </p>
        <button
          type="submit"
          className="bg-[#c6004c] w-full max-w-[250px]  py-4 text-white font-arial font-bold mb-0"
        >
          {submitLoading ? 'Loading...' : 'GET HIRING NOW'}
        </button>
      </Form>
    </div>
  )
}
